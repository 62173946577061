import axios from '@axios'

export default {
  namespaced: true,
  state: {
    load: false,
    projeto: [],
  },
  getters: {
    getLoad(state) {
      return state.load
    },
  },
  mutations: {
    alteraLoading(state, data) {
      state.load = data
    },
    setData(state, data) {
      state.projeto = data
    },
  },
  actions: {
    fetchDados({ commit }, queryParams) {
      commit('alteraLoading', true)

      return new Promise((resolve, reject) => {
        axios
          .get('/projetos', { params: queryParams })
          .then(response => {
            resolve(response.data)
            commit('alteraLoading', false)
          })
          .catch(error => console.log(error))
      })
    },
    getGrupoTarefas({ commit }, idSub) {
      commit('alteraLoading', true)

      return new Promise(resolve => {
        axios
          .get(`/subdisciplinas/${idSub}/favoritos`, {
            params: { perPage: 2000 },
          })
          .then(response => {
            resolve(response.data)
            commit('alteraLoading', false)
          })
          .catch(error => console.log(error))
      })
    },

    fetchChecklists({
      commit,
      dispatch,
    }, queryParams) {
      commit('alteraLoading', true)
      dispatch('show', queryParams.projeto_id)
      return new Promise((resolve, reject) => {
        axios
          .get(`/checklists?projeto=${queryParams.projeto_id}`, {
            params: queryParams,
          })
          .then(response => {
            resolve(response.data)
            commit('alteraLoading', false)
          })
          .catch(error => console.log(error))
      })
    },

    fetchLocalizacoes({
      commit,
      dispatch,
    }, queryParams) {
      commit('alteraLoading', true)
      dispatch('show', queryParams.projeto_id)
      return new Promise((resolve, reject) => {
        axios
          .get(`/localizacoes?projeto=${queryParams.projeto_id}`, {
            params: queryParams,
          })
          .then(response => {
            resolve(response.data)
            commit('alteraLoading', false)
          })
          .catch(error => console.log(error))
      })
    },

    listaEmpresas() {
      return new Promise((resolve, reject) => {
        axios
          .get('/empresas')
          .then(response => {
            resolve(response.data)
          })
          .catch(error => console.log(error))
      })
    },

    pesquisaTarefas(cxt, itens) {
      return new Promise((resolve, reject) => {
        axios
          .get('/tarefas', {
            params: {
              subdisciplinas: [itens],
              perPage: 2000,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => console.log(error))
      })
    },

    show({ commit }, idProjeto) {
      return new Promise((resolve, reject) => {
        axios
          .get(`projetos/${idProjeto}`)
          .then(response => resolve(commit('setData', response.data)))
          .catch(error => reject(error))
      })
    },

    store(ctx, itemData) {
      const formData = new FormData()

      formData.append('descricao', itemData.descricao)
      formData.append('ano', itemData.ano)
      formData.append('cliente', itemData.cliente)
      formData.append('data_entrega', itemData.data_entrega)

      formData.append('formato_id', itemData.formato)

      if (itemData.disciplinas !== undefined) {
        const listaDisciplinas = itemData.disciplinas.map(item => {
          if (item.id) {
            return item.id
          }
          return item.uuid
        })

        Object.keys(listaDisciplinas)
          .forEach(chave => {
            formData.append('disciplinas[]', listaDisciplinas[chave])
          })
      }
      const listaTimes = itemData.times.map(item => {
        if (item.id) {
          return item.id
        }
        return item.uuid
      })

      const listaResponsaveis = itemData.responsaveis.map(item => {
        if (item.id) {
          return item.id
        }
        return item.uuid
      })

      Object.keys(listaTimes)
        .forEach(chave => {
          formData.append('times[]', listaTimes[chave])
        })

      Object.keys(listaResponsaveis)
        .forEach(chave => {
          formData.append('users[]', listaResponsaveis[chave])
        })

      // eslint-disable-next-line array-callback-return
      itemData.empresa.map(item => {
        formData.append('empresas[]', item)
      })

      return new Promise((resolve, reject) => {
        axios
          .post('/projetos', formData)
          .then(() => resolve())
          .catch(error => reject(error))
      })
    },

    update(ctx, itemData) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()

        formData.append('descricao', itemData.descricao)
        formData.append('ano', itemData.ano)
        formData.append('cliente', itemData.cliente.uuid)
        formData.append('empresa', itemData.empresa)
        formData.append('data_entrega', itemData.data_entrega)

        if (itemData.formato) {
          formData.append('formato_id', itemData.formato.uuid || itemData.formato.id)
        }

        const listaDisciplinas = itemData.disciplinas.map(item => {
          if (item.id) {
            return item.id
          }
          return item.uuid
        })

        const listaTimes = itemData.times.map(item => {
          if (item.id) {
            return item.id
          }
          return item.uuid
        })

        const listaResponsaveis = itemData.users.map(item => {
          if (item.id) {
            return item.id
          }
          return item.uuid
        })

        Object.keys(listaDisciplinas)
          .forEach(chave => {
            formData.append('disciplinas[]', listaDisciplinas[chave])
          })

        Object.keys(listaTimes)
          .forEach(chave => {
            formData.append('times[]', listaTimes[chave])
          })

        Object.keys(listaResponsaveis)
          .forEach(chave => {
            formData.append('users[]', listaResponsaveis[chave])
          })

        itemData.empresa.map(item => {
          formData.append('empresas[]', item)
        })

        formData.append('_method', 'PUT')

        axios
          .post(`projetos/${itemData.uuid}`, formData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    createLocalizacao(ctx, itemData) {
      return new Promise((resolve, reject) => {
        axios
          .post('localizacoes', {
            descricao: itemData.descricao,
            projeto_id: itemData.projeto,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    updateLocalizacao(ctx, itemData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`localizacoes/${itemData.uuid}`, itemData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    deleteItem(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/projetos/${id}`)
          .then(response => resolve())
          .catch(error => reject(error))
      })
    },
    deleteChecklist(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/checklists/${id}`)
          .then(response => resolve())
          .catch(error => reject(error))
      })
    },
    deleteLocalizacao(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/localizacoes/${id}`)
          .then(response => resolve())
          .catch(error => reject(error))
      })
    },
  },
}
